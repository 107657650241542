<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.order_no" placeholder="订单号" style="width: 220px;" clearable/>
            <!-- 店铺管理员隐藏该选型 -->
            <template v-if="user.user.type !== 'shop'">
                <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 140px" clearable>
                    <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                    </el-option>
                </el-select>
            </template>
            <el-select class="filter-item" v-model="listQuery.status" placeholder="审核状态" style="width: 140px" clearable>
                <el-option label="待审核" :value="0"></el-option>
                <el-option label="已通过" :value="1"></el-option>
                <el-option label="已驳回" :value="2"></el-option>
            </el-select>
            <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
            <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
            <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExportExcel">导出</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column type="expand" min-width="40">
                <template slot-scope="props">
                    <div style="margin-left: 10%">
                        <el-form label-position="left" inline class="custom-inline-form">
                            <el-row>赔付信息</el-row>

                            <el-row>
                                <el-form-item label="内容:">
                                    <span>{{ props.row.content }}</span>
                                </el-form-item>
                            </el-row>

                            <el-row>
                                <el-form-item label="图片:">
                                    <viewer :images="props.row.imgs">
                                        <img v-for="(src,index) in props.row.imgs" :src="src" :key="index" style="margin-right: 10px; width: 100px; height: 100px; object-fit: contain; border: 1px solid #ccc; border-radius: 5px; cursor: pointer;">
                                    </viewer>
                                </el-form-item>
                            </el-row>
                        </el-form>

                        <el-form v-if="props.row.status == 1" label-position="left" inline class="custom-inline-form">
                            <el-row>审核通过</el-row>

                            <el-form-item label="赔付金额:">
                                <span>{{ props.row.compensation_money }}</span>
                            </el-form-item>
                        </el-form>
                        <el-form v-else-if="props.row.status == 2" label-position="left" inline class="custom-inline-form">
                            <el-row>审核驳回</el-row>

                            <el-form-item label="驳回原因:">
                                <span>{{ props.row.refuse_reason }}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </template>
            </el-table-column>

            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column label="订单号" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_no }}
                </template>
            </el-table-column>
            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.order.shop.shop_name }}</div>
                </template>
            </el-table-column>
            <el-table-column label="实付金额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.pay_money }}
                </template>
            </el-table-column>
            <el-table-column label="配送费" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.send_price }}
                </template>
            </el-table-column>
            <el-table-column label="订单状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.order.order_status == 0">未接单</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_status == 1">已接单</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_status == 2">待评价</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.order_status == 3">已完成</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.order.order_status == 4">已取消</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="发货状态" min-width="80" align="center">
                <template slot-scope="scope">
                  <!-- 兼容商城发货 -->
                  <el-tag type="warning" v-if="scope.row.order.order_type == 2 && scope.row.order.shipping_status == 1">配送中</el-tag>
                  <el-tag type="info" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 0">待发货</el-tag>
                  <el-tag type="info" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 1">待收货</el-tag>
                  <el-tag type="success" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 2">已收货</el-tag>

                  <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0 && scope.row.order.send_type == 3">待出餐</el-tag>
                  <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0 && scope.row.order.send_type == 4">待堂食</el-tag>
                  <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0">未配送</el-tag>
                  <el-tag type="warning" v-else-if="scope.row.order.shipping_status == 1 && scope.row.order.send_type == 3">待自提</el-tag>
                  <el-tag type="warning" v-else-if="scope.row.order.shipping_status == 1">配送中</el-tag>
                  <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2 && scope.row.order.send_type == 3">已自提</el-tag>
                  <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2 && scope.row.order.send_type == 4">已堂食</el-tag>
                  <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2">已送达</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="支付状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.order.pay_status == 0">未支付</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.pay_status == 1">已支付</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.order.pay_status == 2">已退款</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="支付时间" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.pay_time }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="申请时间" min-width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.created_at }}</span>
                </template>
            </el-table-column>
            <el-table-column label="审核状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.status == 0">待审核</el-tag>
                    <el-tag type="success" v-else-if="scope.row.status == 1">已通过</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status == 2">已驳回</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="预计赔付金额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.compensation_money || (((scope.row.order.pay_money - scope.row.order.send_price) * scope.row.order.school.compensation_ratio).toFixed(2) > 0 ? ((scope.row.order.pay_money - scope.row.order.send_price) * scope.row.order.school.compensation_ratio).toFixed(2) : 0) }}
                </template>
            </el-table-column>
            <!-- 平台审核 -->
            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width" v-if="user.user.type === 'admin'">
                <template slot-scope="scope">
                    <div>
                        <el-button v-if="scope.row.status == 0" type="warning" size="mini" @click="handleExamine(scope.row)">审核</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-table style="display: none" id="exportTable" v-loading="listLoading" :data="exportExcels" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="订单号" min-width="120" align="center">
                <template slot-scope="scope">
                    `{{ scope.row.order_no }}
                </template>
            </el-table-column>
            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.order.shop.shop_name }}</div>
                </template>
            </el-table-column>
            <el-table-column label="实付金额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.pay_money }}
                </template>
            </el-table-column>
            <el-table-column label="配送费" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.send_price }}
                </template>
            </el-table-column>
            <el-table-column label="订单状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.order.order_status == 0">未接单</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_status == 1">已接单</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_status == 2">待评价</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.order_status == 3">已完成</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.order.order_status == 4">已取消</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="发货状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <!-- 兼容商城发货 -->
                    <el-tag type="warning" v-if="scope.row.order.order_type == 2 && scope.row.order.shipping_status == 1">配送中</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 0">待发货</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 1">待收货</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.order_type == 2 && scope.row.order.shipment_status == 2">已收货</el-tag>

                    <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0 && scope.row.order.send_type == 3">待出餐</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0 && scope.row.order.send_type == 4">待堂食</el-tag>
                    <el-tag type="info" v-else-if="scope.row.order.shipping_status == 0">未配送</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.order.shipping_status == 1 && scope.row.order.send_type == 3">待自提</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.order.shipping_status == 1">配送中</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2 && scope.row.order.send_type == 3">已自提</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2 && scope.row.order.send_type == 4">已堂食</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.shipping_status == 2">已送达</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="支付状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.order.pay_status == 0">未支付</el-tag>
                    <el-tag type="success" v-else-if="scope.row.order.pay_status == 1">已支付</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.order.pay_status == 2">已退款</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="支付时间" min-width="120" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order.pay_time }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="申请时间" min-width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.created_at }}</span>
                </template>
            </el-table-column>
            <el-table-column label="审核状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.status == 0">待审核</el-tag>
                    <el-tag type="success" v-else-if="scope.row.status == 1">已通过</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.status == 2">已驳回</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="预计赔付金额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.compensation_money || (((scope.row.order.pay_money - scope.row.order.send_price) * scope.row.order.school.compensation_ratio).toFixed(2) > 0 ? ((scope.row.order.pay_money - scope.row.order.send_price) * scope.row.order.school.compensation_ratio).toFixed(2) : 0) }}
                </template>
            </el-table-column>
            <el-table-column label="赔付金额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.compensation_money }}
                </template>
            </el-table-column>

            <el-table-column label="驳回原因" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.refuse_reason }}
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 审核 -->
        <el-dialog title="赔付审核" :visible.sync="dialogExamineVisible" width="60%" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="审核结果" prop="status">
                    <el-radio-group v-model="form.status" size="small">
                        <el-radio :label="1" border>通过</el-radio>
                        <el-radio :label="2" border>驳回</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="form.status == 2" label="驳回原因" prop="refuse_reason" :rules="[{required:true,message:'驳回原因不能为空'}]">
                    <el-input type="textarea" :rows="4" v-model="form.refuse_reason"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogExamineVisible = false">取消</el-button>
                <el-button type="primary" @click="saveExamineData" :loading="btnLoading"
                >确定</el-button
                >
            </div>
        </el-dialog>
        <!-- 审核 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import FileSaver from "file-saver";
    import XLSX from "xlsx";

    export default {
        data() {
            return {
                shops: [],
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    order_no: '',
                    school_id: '',
                    shop_id: '',
                    status: '',
                    start_time: '',
                    end_time: '',
                },
                // 审核
                dialogExamineVisible: false,
                btnLoading: false,
                form:{
                    order_no: '',
                    status: 1,
                    refuse_reason: '',
                },
                // 导出
                exportExcels: [],
            };
        },
        created() {
            this.getList();
            this.getShopList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                // 店铺管理员只能看到自己的订单
                if (this.user.user.type === 'shop') {
                    this.listQuery.shop_id = this.user.user.shop_id;
                }
                this.listLoading = true;
                request({
                    url: "/api/backend/order/compensationList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 审核
            resetForm() {
                this.form = {
                    order_no: '',
                    status: 1,
                    refuse_reason: '',
                }
                this.btnLoading = false
            },
            async handleExamine(row){
                // 操作密码
                const { password } = await this.$oprationPassword()
                await request({
                    url: "/api/backend/school/checkOperationPassword",
                    method: "get",
                    params: {
                        id: row.school_id,
                        operation_password: password,
                    },
                }).then(() => {

                }).catch(() => {
                    return new Promise((resolve, reject) => {
                        this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
                    })
                });

                this.resetForm()
                this.form.order_no = row.order_no
                this.dialogExamineVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveExamineData(){
                this.$refs['form'].validate(valid => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/order/compensationExamine',
                            method: 'post',
                            data: this.form
                        }).then(response => {
                            this.btnLoading = false
                            this.dialogExamineVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList()
                        }).catch((err) => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            // 导出
            handleExportExcel() {
                let listQuery = JSON.parse(JSON.stringify(this.listQuery))
                listQuery.page = 1
                listQuery.limit = this.total
                request({
                    url: "/api/backend/order/compensationList",
                    method: "get",
                    params: listQuery
                }).then(response => {
                    this.exportExcels = response.data.data
                    this.$nextTick(function() {
                        let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"));
                        /* get binary string as output */
                        let wbout = XLSX.write(wb, {
                            bookType: "xlsx",
                            bookSST: true,
                            type: "array"
                        });
                        try {
                            FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "赔付订单.xlsx");
                        } catch (e) {
                            if (typeof console !== "undefined") console.log(e, wbout);
                        }
                    })
                })
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
